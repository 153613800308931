<template>
    <div class="about">
        <div class="banner">
            <div class="main_content">
                <div class="about_name_bg">
                    <div class="about_name">公司介绍</div>
                </div>
                <img class="about_en" src="./images/aboutus.png" alt="img"/>
            </div>
        </div>
        <div class="main_content">
            <div class="about_title" style="color: #FF4C02">关于淘云</div>
            <div class="about_box">
                <div class="about_col1">
                    <img class="logo" src="../../../components/Header/images/logo.svg" alt="logo">
                </div>
                <div class="about_col2">
                    杭州淘云科技有限公司隶属于杭州光云科技股份有限公司（股票代码：688365），公司成立于2012年，主要产品包含快麦打印机、番茄标签、番茄打印管家、快麦云打印服务、番茄打印解决方案。<br/><br/>
                    作为行业领先的热敏打印解决方案提供商，快麦打印机累计热销150W+台打印机，服务客户遍布全球，产品远销美国、欧洲、东南亚等。积极与行业内的伙伴合作，推出菜鸟定制版打印机、拼多多定制版打印机，秦丝定制版打印机；并成为国内知名的快递公司如百世、圆通、韵达、极兔等供应商。
                </div>
            </div>
            <div class="about_title">关于光云</div>
            <div class="about_box" style="margin-bottom: 165rem;">
                <div class="about_col2 about_col3">
                    光云科技，A股电商SaaS第一股（股票代码：688365），成立于2009年，总部位于中国电商之都杭州，是互联网SaaS模式的软件技术创新型企业。作为中国首批电商SaaS服务商，光云科技秉承“让企业经营更卓越”的使命，专注于为企业提供电商运营的全链路解决方案，致力于成为全球企业软件服务领域的领跑者。
                    <br/><br/>
                    光云科技基于电子商务平台为电商商家提供SaaS产品，并在此基础上提供配套硬件、运营服务等增值产品及服务。围绕淘宝、天猫、京东、拼多多、抖音电商、快手电商等国内主流电商平台，光云科技已打造面向大中小型商家的电商SaaS产品体系，在线服务商家400万+，付费商家120万+。目前，旗下拥有以超级店长、快递助手、旺店系列为核心的中小商家电商SaaS产品；以快麦ERP、快麦小智、深绘、绫云、巨沃WMS、快麦智库为核心的大商家SaaS产品。
                    <br/><br/>
                    此外，不断拓展SaaS服务外延，借势钉钉平台，光云科技布局企业服务SaaS领域，目前已推出有成CRM、有成财务、有成报销、有成会议、有成会务、番茄表单等有成系列SaaS产品。
                </div>
                <div class="about_col4">
                    <img class="logo" src="./images/bg1.png" alt="logo">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "About",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.about {
  padding-top: 0;

  .banner {
    background: #2B2A2A;
    height: 200rem;

    .main_content {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      height: 100%;

      .about_name_bg {
        width: 273rem;
        height: 58rem;
        background: #FF4C02;
        text-align: right;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .about_name {
          font-size: 40rem;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 500;
          color: #000000;
        }
      }

      .about_en {
        height: 103rem;
      }
    }
  }

  .about_title {
    margin-top: 70rem;
    font-size: 36rem;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 500;
    color: #000000;
    line-height: 52rem;
    text-align: center;
  }

  .about_box {
    margin-top: 34rem;
    display: flex;
    flex-direction: row;

    .about_col1 {
      flex: 0 0 50%;
      height: 461rem;
      background: #000000;
      border-radius: 40rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 344rem;
      }
    }

    .about_col2 {
      flex: 0 0 50%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 66rem;
      height: 461rem;
      background: #FF4C02;
      border-radius: 40rem;
      font-size: 20rem;
      font-family: Gilroy-MediumItalic, Gilroy-MediumItalic;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28rem;
    }

    .about_col3 {
      background: #2D64E6;
      height: 728rem;
    }

    .about_col4 {
      height: 728rem;
    }
  }
}
</style>
