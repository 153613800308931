<template>
    <div class="menus">
        <template v-for="(menu, index) in routes">
            <div
                v-show="!menu.hidden"
                class="menu-item"
                :class="{ active: menu.path && selectedPath === menu.path }"
                :key="menu.path"
                @click="routeTo(menu)"
            >
                <div>{{ menu.name }}</div>
                <div class="sub_menus" v-if="menu.children && menu.children.length>0">
                    <template v-for="(subMenu, subIndex) in menu.children">
                        <div class="sub_menu" :class="{ active: subMenu.path && subSelectedPath === subMenu.path }"
                             :key="subMenu.path" @click="subRouteTo(subMenu)">
                            {{ subMenu.name }}
                        </div>
                    </template>
                </div>
            </div>
            <div class="menu_split" :key="menu.path + index" v-if="index<routes.length-1">/</div>
        </template>
    </div>
</template>

<script>
import {routes} from "../../router";

export default {
    name: "Menus",
    data() {
        return {
            routes: [],
            selectedPath: "",
            subSelectedPath: "",
        };
    },
    mounted() {
        this.routes = []
        routes.map(item => {
            if (!item.hidden) {
                this.routes.push(item)
            }
        })

        this.selectedPath = ''
        this.subSelectedPath = ''
        let path = this.$route.path
        this.routesMap(path)
        this.$router.afterEach((to) => {
            this.routesMap(to.path)
        });
    },
    methods: {
        routesMap(path) {
            this.routes.some(route => {
                if (path === route.path) {
                    this.selectedPath = path;
                    this.subSelectedPath = '';
                    return true
                }
                if (route.children) {
                    route.children.some(children => {
                        if (path === children.path) {
                            this.subSelectedPath = path;
                            return true;
                        }
                    })
                    if (this.subSelectedPath) {
                        this.selectedPath = route.path
                    }
                }
            })
        },
        routeTo(menu) {
            if (menu.children && menu.children.length > 0) {
                /*let route = menu.children[0].path
                if (!route || route === this.subSelectedPath) {
                    return;
                }
                this.selectedPath = menu.path;
                this.subSelectedPath = route
                this.$router.push(route);*/
            } else {
                let route = menu.path
                if (!route || route === this.selectedPath) {
                    return;
                }
                this.selectedPath = route;
                this.subSelectedPath = '';
                this.$router.push(route);
            }
        },
        subRouteTo(menu) {
            let route = menu.path
            if (!route || route === this.subSelectedPath) {
                return;
            }
            this.subSelectedPath = route
            this.$router.push(route);
            console.log(route)
        }
    },
};
</script>

<style lang="scss" scoped>
.menus {
  display: flex;
  flex-direction: row;

  .menu-item {
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    height: 18rem;
    font-size: 22rem;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 400;
    color: #ffffff;

    &.active {
      position: relative;
      color: #FF4C02;
      transition: all 0.3s;

      &::after {
        position: absolute;
        bottom: -16rem;
        left: 0;
        content: '';
        width: 100%;
        height: 2rem;
        background: #FF4C02;
      }
    }

    &:hover {
      color: #FF4C02;
      transition: all 0.3s;

      .sub_menus {
        display: block;
      }
    }

    .sub_menus {
      position: absolute;
      right: 0;
      top: 42rem;
      display: none;
      padding: 16rem;
      background: #000000;
      border-radius: 9rem;
      border: 1rem solid #FF4C02;
      z-index: 9;

      &::before {
        content: '';
        position: absolute;
        top: -20rem;
        left: 0;
        width: 100%;
        height: 20rem;
        background: transparent;
      }

      &::after {
        content: '';
        position: absolute;
        top: -10rem;
        left: 50%;
        border-top: 4rem solid #FF4C02;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        transform: translate(-50%, 0);
      }

      .sub_menu {
        height: 28rem;
        font-size: 16rem;
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 400;
        line-height: 27rem;
        white-space: nowrap;
        color: #ffffff;
        transition: all 0.3s;

        &:hover {
          color: #FF4C02;
          transition: all 0.3s;
        }

        &.active {
          color: #FF4C02;
        }
      }
    }
  }

  .menu_split {
    margin: 0 32rem;
    font-size: 24rem;
    color: #FFFFFF;
    font-weight: 400;
  }
}
</style>
