<template>
    <div class="home">
        <div class="banner">
            <el-carousel height="518rem" trigger="click" :interval="5000" arrow="never">
                <el-carousel-item>
                    <div class="banner_container">
                        <div class="main_content">
                            <div class="banner_title">让打印更简单</div>
                            <div class="banner_text">
                                软硬件结合&nbsp;&nbsp;提供智能打印解决方案
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="home_container home_container_write">
            <div class="main_content">
                <div class="home_title">产品与解决方案</div>
                <div class="home_sub_title">软硬件结合，提供智能打印解决方案</div>
                <div class="home_row">
                    <div class="home_col home_col1">
                        <div class="home_col_title">
                            <img class="home_col_title_icon" src="./images/7.png" alt="icon">
                            面向终端用户
                        </div>
                        <div class="home_col_item" @click="openNew('https://dyj.kuaimai.com/')">
                            <img class="home_col_item_icon" src="./images/1.png" alt="icon">
                            <div class="home_col_item_content">
                                <div class="home_col_item_content_title">快麦打印机</div>
                                <div class="home_col_item_content_text">
                                    快递面单打印机、标签打印机、<br/>
                                    零售小票打印机、智能PDA等。
                                </div>
                            </div>
                        </div>
                        <div class="home_col_item" @click="openNew('https://gj.fanqie365.cn/')">
                            <img class="home_col_item_icon" src="./images/2.png" alt="icon">
                            <div class="home_col_item_content">
                                <div class="home_col_item_content_title">番茄打印管家</div>
                                <div class="home_col_item_content_text">
                                    打印机管理软件，<br/>
                                    驱动下载、打印机设置、<br/>
                                    PDF智能裁剪与分割打印、远程打印等。
                                </div>
                            </div>
                        </div>
                        <div class="home_col_item" @click="openNew('https://fanqie365.cn/')">
                            <img class="home_col_item_icon" src="./images/3.png" alt="icon">
                            <div class="home_col_item_content">
                                <div class="home_col_item_content_title">番茄标签软件</div>
                                <div class="home_col_item_content_text">
                                    免费标签编辑软件，覆盖电脑、手机<br/>
                                    端，支持主流品牌打印机。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="home_col home_col2">
                        <div class="home_col_title">
                            <img class="home_col_title_icon" src="./images/8.png" alt="icon">
                            面向企业
                        </div>
                        <div class="home_col_item" @click="openNew('https://cloudprint.kuaimai.com/#/home')">
                            <img class="home_col_item_icon" src="./images/4.svg" alt="icon">
                            <div class="home_col_item_content">
                                <div class="home_col_item_content_title">快麦云打印</div>
                                <div class="home_col_item_content_text">
                                    为SaaS企业提供的软硬件结合的打印服<br/>
                                    务，支持异地远程、多人跨端打印等；提供<br/>
                                    支持多平台对接（Windows、Mac、iOS 、<br/>
                                    Android等）的API接口。
                                </div>
                            </div>
                        </div>
                        <div class="home_col_item" @click="openNew('https://solution.kuaimai.com/')">
                            <img class="home_col_item_icon" src="./images/5.png" alt="icon">
                            <div class="home_col_item_content">
                                <div class="home_col_item_content_title">番茄打印解决方案</div>
                                <div class="home_col_item_content_text">
                                    为SaaS企业提供标签/小票模板编辑与跨平<br/>
                                    台打印解决方案，提供ApI接口低成本对接<br/>
                                    即可实现SaaS用户在线模板编辑打印，为<br/>
                                    企业降本增效。
                                </div>
                            </div>
                        </div>
                        <div class="home_col_item" @click="openNew('https://iot.kuaimai.com/#/home')">
                            <img class="home_col_item_icon" src="./images/6.svg" alt="icon">
                            <div class="home_col_item_content">
                                <div class="home_col_item_content_title">快麦IoT</div>
                                <div class="home_col_item_content_text">
                                    快麦自主研发的软硬件服务套餐，旨在为硬<br/>
                                    件厂商和第三方应用提供智能化解决方案。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home_container home_container_bg2">
            <div class="main_content main_box">
                <div class="main_item">
                    <div class="main_item_title">3,000,000</div>
                    <div class="main_item_text">累计服务客户数</div>
                </div>
                <div class="main_item">
                    <div class="main_item_title">30+</div>
                    <div class="main_item_text">国家和地区</div>
                </div>
                <div class="main_item">
                    <div class="main_item_title">1,500,000</div>
                    <div class="main_item_text">累计售出打印</div>
                </div>
                <div class="main_item">
                    <div class="main_item_title">148</div>
                    <div class="main_item_text">累计发布产品数量</div>
                </div>
                <div class="main_item">
                    <div class="main_item_title">60,000,000</div>
                    <div class="main_item_text">打印订单数</div>
                </div>
            </div>
        </div>
        <div class="home_container home_container_gray">
            <div class="main_content">
                <div class="home_title">我们的客户</div>
                <div class="customer_box">
                    <img class="customer_item" src="./images/logo/1.jpg" alt="icon">
                    <img class="customer_item" src="./images/logo/2.jpeg" alt="icon">
                    <img class="customer_item" src="./images/logo/3.jpeg" alt="icon">
                    <img class="customer_item" src="./images/logo/4.jpg" alt="icon">
                    <img class="customer_item" src="./images/logo/5.jpg" alt="icon">
                    <img class="customer_item" src="./images/logo/7.jpg" alt="icon">
                    <img class="customer_item" src="./images/logo/8.jpg" alt="icon">
                    <img class="customer_item" src="./images/logo/9.jpg" alt="icon">
                    <img class="customer_item" src="./images/logo/10.jpeg" alt="icon">
                    <img class="customer_item" src="./images/logo/11.jpeg" alt="icon">
                    <img class="customer_item" src="./images/logo/12.jpg" alt="icon">
                    <img class="customer_item" src="./images/logo/13.jpg" alt="icon">
                    <img class="customer_item" src="./images/logo/14.jpg" alt="icon">
                    <img class="customer_item" src="./images/logo/6.png" alt="icon">
                    <img class="customer_item" src="./images/logo/15.png" alt="icon">
                    <img class="customer_item" src="./images/logo/16.png" alt="icon">
                    <img class="customer_item" src="./images/logo/17.png" alt="icon">
                    <img class="customer_item" src="./images/logo/18.png" alt="icon">
                    <img class="customer_item" src="./images/logo/19.png" alt="icon">
                    <img class="customer_item" src="./images/logo/20.png" alt="icon">
                    <img class="customer_item" src="./images/logo/21.png" alt="icon">
                    <img class="customer_item" src="./images/logo/22.png" alt="icon">
                </div>
            </div>
        </div>
        <div class="home_container home_container_write">
            <div class="main_content">
                <div class="home_title">企业动态</div>
                <div class="dynamics_box">
                    <div class="dynamics_left" @click="openNew('https://www.raycloud.com/gongsixinwen/36733.jhtml')">
                        <img class="dynamics_left_img" src="./images/dt1.png" alt="img">
                        <div class="dynamics_left_title">湖南省委副书记、省长毛伟明率队考察光云科技</div>
                    </div>
                    <div class="dynamics_right">
                        <div class="dynamics_list" @click="openNew('https://mp.weixin.qq.com/s/vfT7iAu2vW7Lu394_LY8TA')">
                            <img class="dynamics_list_img" src="./images/dt2.png" alt="img">
                            <div class="dynamics_list_content">
                                <div class="dynamics_list_title">
                                    快麦云打印，支持异地远程、多人跨端打印～
                                </div>
                                <div class="dynamics_list_text">
                                    云打印是一套基于硬件打印机和软件服务的解决方案；打印机通过WiFi、4G、以太网入网，打印数据通过云端下发，即可实现异地、多人远程打印。云打印不限打印距离、不限使用人数，不限使用场景，广泛应用于各个有打印需求的场景，如快递物流面单、标签、小票打印等，适用于零售、电商、线下批发、餐饮等各行业。
                                </div>
                            </div>
                        </div>
                        <div class="dynamics_list" @click="openNew('https://www.raycloud.com/gongsixinwen/36757.jhtml')">
                            <img class="dynamics_list_img" src="./images/dt3.jpeg" alt="img">
                            <div class="dynamics_list_content">
                                <div class="dynamics_list_title">
                                    产品为王，光云科技荣膺WISE2023未来商业之王企业服务领域年度企业
                                </div>
                                <div class="dynamics_list_text">
                                    11月28-29日，36氪WISE2023 商业之王大会在北京国际会议中心盛大举办。本届大会以“太阳照常升起 The Sun Always Rises”为主题，横跨一个主会场与六大垂直领域专场，邀请全领域商业大咖展开为期两天的顶级商业对话，向现在提问，给未来答案。
                                </div>
                            </div>
                        </div>
                        <div class="dynamics_list" @click="openNew('https://www.raycloud.com/gongsixinwen/36758.jhtml')">
                            <img class="dynamics_list_img" src="./images/dt4.jpeg" alt="img">
                            <div class="dynamics_list_content">
                                <div class="dynamics_list_title">
                                    光云科技：大模型商业化前夕，电商让AI的“子弹”落地
                                </div>
                                <div class="dynamics_list_text">
                                    随着直播电商所带来的结构性繁荣逐渐失去爆发力，流量红利见顶已经不再是一个问题，而是一个循环往复的技术与经济周期。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {};
    },
    methods: {
        openNew(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
.home {
  .banner {
    height: 518rem;

    .banner_container {
      height: 518rem;
      background: url("./images/bg1.png") center / 1920rem 518rem no-repeat;

      .banner_title {
        padding-top: 200rem;
        padding-left: 37rem;
        font-size: 64rem;
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 700;
        color: #FF4C02;
        line-height: 93rem;
      }

      .banner_text {
        margin-top: 17rem;
        padding-left: 37rem;
        font-size: 36rem;
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 700;
        color: #ffffff;
        line-height: 52rem;
      }
    }
  }

  .home_container {
    padding: 96rem 0 89rem 0;

    &.home_container_gray {
      background: #EFEFEF;
    }

    &.home_container_write {
      background: #FFFFFF;
    }

    .home_title {
      display: block;
      text-align: center;
      font-size: 36rem;
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 500;
      color: #FF4C02;
      line-height: 52rem;
    }

    .home_sub_title {
      display: block;
      text-align: center;
      margin-top: 6rem;
      font-size: 24rem;
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 400;
      color: #333333;
      line-height: 35rem;
    }

    .home_row {
      margin-top: 44rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .home_col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-radius: 40rem;
        height: 765rem;

        &.home_col1 {
          background: #FF4C02;
          width: 50%;
        }

        &.home_col2 {
          background: #333333;
          width: 50%;
        }

        .home_col_title {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 48rem 0 0 0;
          font-size: 24rem;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 35rem;

          .home_col_title_icon {
            margin-right: 9rem;
            width: 27rem;
            height: 27rem;
          }
        }

        .home_col_item {
          margin-top: 33rem;
          padding: 32rem 0;
          width: 500rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          background: #ffffff;
          border-radius: 18rem;
          cursor: pointer;
          transition: all 0.5s;
          transform: scale(1);

          &:hover {
            background: #EFEFEF;
            box-shadow: 0rem 0rem 10rem 5rem rgba(0, 0, 0, 0.2);
            transition: all 0.5s;
            transform: scale(1.02);
          }

          .home_col_item_icon {
            margin-left: 36rem;
            margin-right: 44rem;
            width: 64rem;
          }

          .home_col_item_content {
            .home_col_item_content_title {
              font-size: 20rem;
              font-family: Source Han Sans, Source Han Sans;
              font-weight: 500;
              color: #FF4C02;
              line-height: 29rem;
            }

            .home_col_item_content_text {
              font-size: 16rem;
              font-family: Source Han Sans, Source Han Sans;
              font-weight: 400;
              color: #000000;
              line-height: 24rem;
            }
          }
        }
      }
    }

    .customer_box {
      margin-top: 50rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .customer_item {
        margin: 0 55rem 34rem 0;
        width: 100rem;

        &:nth-child(8n) {
          margin-right: 0;
        }
      }
    }

    .dynamics_box {
      margin-top: 37rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      .dynamics_left {
        margin-right: 44rem;
        padding: 25rem;
        flex: 0 0 470rem;
        background: #333333;
        cursor: pointer;

        .dynamics_left_img {
          width: 100%;
        }

        .dynamics_left_title {
          margin-top: 11rem;
          font-size: 18rem;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 26rem;
        }
      }

      .dynamics_right {
        .dynamics_list {
          margin-bottom: 15rem;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          cursor: pointer;

          .dynamics_list_img {
            width: 180rem;
          }

          .dynamics_list_content {
            margin-left: 15rem;

            .dynamics_list_title {
              font-size: 16rem;
              font-family: Source Han Sans, Source Han Sans;
              font-weight: 500;
              color: #3D3D3D;
              line-height: 23rem;
            }

            .dynamics_list_text {
              margin-top: 5rem;
              font-size: 14rem;
              font-family: Source Han Sans, Source Han Sans;
              font-weight: 500;
              color: #999999;
              line-height: 20rem;
            }
          }
        }
      }
    }
  }

  .home_container_bg2 {
    padding: 0;
    height: 295rem;
    background: url("./images/bg2.png") center / 1920rem 295rem no-repeat;

    .main_box {
      height: 295rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .main_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .main_item_title {
          font-size: 36rem;
          font-family: Roboto, Roboto;
          font-weight: 700;
          color: #FF4C02;
          line-height: 42rem;
        }

        .main_item_text {
          margin-top: 27rem;
          font-size: 18rem;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 26rem;
        }
      }
    }
  }
}
</style>
