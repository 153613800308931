<template>
    <div class="footer">
        <div class="footer_container">
            <div class="main_content footer_row">
                <div class="footer_col">
                    <div class="footer_col_title">硬件产品</div>
                    <div class="footer_col_text" @click="openNew('https://dyj.kuaimai.com/')">
                        快麦打印机
                    </div>
                    <div class="footer_col_text" @click="openNew('https://cloudprint.kuaimai.com/#/home')">
                        快麦云打印
                    </div>
                </div>
                <div class="footer_col">
                    <div class="footer_col_title">软件与解决方案</div>
                    <div class="footer_col_text" @click="openNew('https://gj.fanqie365.cn/')">番茄打印管家</div>
                    <div class="footer_col_text" @click="openNew('https://fanqie365.cn/')">番茄标签</div>
                    <div class="footer_col_text" @click="openNew('https://solution.kuaimai.com/')">
                        番茄打印解决方案
                    </div>
                    <div class="footer_col_text" @click="openNew('https://iot.kuaimai.com/#/home')">快麦IoT</div>
                </div>
                <div class="footer_col">
                    <div class="footer_col_title">售后客服</div>
                    <div class="footer_col_phone">400-007-5533</div>
                    <div class="footer_col_text">工作时间：周一至周日 9:00 - 21:00</div>
                </div>
                <div class="footer_col">
                    <div class="footer_col_title">市场合作</div>
                    <img class="footer_qrcode" src="./images/qrcode.png" alt="qrcode"/>
                </div>
                <div class="footer_col">
                    <img class="footer_logo" src="../Header/images/logo.svg" alt="logo"/>
                </div>
            </div>
        </div>
        <div class="footer_bottom">
            <div class="main_content">
                杭州淘云科技有限公司 版权所有 浙ICP备09083305号 浙公网安备 33010802003954号
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {};
    },
    mounted() {
    },
    methods: {
        openNew(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
/* 底部样式 */
.footer {
  //padding-bottom: 200rem;

  .footer_container {
    height: 274rem;
    background: #000000;

    .footer_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .footer_col {
        margin-top: 60rem;

        .footer_col_title {
          margin-bottom: 13rem;
          line-height: 26rem;
          font-size: 18rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FD6520;
        }

        .footer_col_text {
          margin-bottom: 13rem;
          line-height: 20rem;
          font-size: 14rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
        }

        .footer_col_phone {
          margin: 33rem 0 22rem 0;
          font-size: 36rem;
          font-family: Alibaba Sans-Regular, Alibaba Sans;
          font-weight: 400;
          color: #FD6520;
          line-height: 28rem;
        }

        .footer_qrcode {
          width: 106rem;
        }

        .footer_logo {
          margin-top: 55rem;
          width: 191rem;
        }
      }
    }
  }

  .footer_bottom {
    height: 60rem;
    line-height: 60rem;
    font-size: 14rem;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    background: #333333;
  }
}
</style>
