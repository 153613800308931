<template>
    <div class="header">
        <div class="main_content header_content">
            <div class="logo_container">
                <img class="logo_icon" src="./images/logo.svg" alt="logo"/>
            </div>
            <div class="menus">
                <Menus></Menus>
            </div>
        </div>
    </div>
</template>

<script>
import Menus from "../Menus/Menus";

export default {
    name: "Header",
    components: {Menus},
    data() {
        return {};
    },
    mounted() {
    },
    methods: {
        openNew(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
/* 顶部样式 */
.header {
  z-index: 999;
  background: #000000;

  .header_content {
    height: 100rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .logo_container {
    .logo_icon {
      height: 50rem;
    }
  }

  .menus {
    margin: 0 38rem;
    //flex: 1 1 auto;
  }
}
</style>
