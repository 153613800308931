<template>
    <div class="about">
        <div class="banner">
            <div class="main_content">
                <div class="about_name_bg">
                    <div class="about_name">公司介绍</div>
                </div>
                <img class="about_en" src="../aboutUs/images/aboutus.png" alt="img"/>
            </div>
        </div>
        <div class="main_content">
            <div class="about_title">发展历程</div>
            <div class="time_line">
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title"></div>
                        <div class="left_text"></div>
                    </div>
                    <div class="time_line_center">
                        <img class="center_img" src="./images/line.png" alt="line">
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title">番茄打印管家重磅升级</div>
                        <div class="left_text">
                            功能迭代升级，PDF智能裁剪及分割、<br>
                            USB机器远程打印、远程售后等。
                        </div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2023</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer1.png" alt="printer">
                            <div class="right_text">全系列打印产品线</div>
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title">番茄打印管家上线</div>
                        <div class="left_text">
                            打印机管理工具，打印机驱动安装、<br>打印机设置、打印异常诊断等。
                        </div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2022</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer2.png" alt="printer">
                            <div class="right_text">拼多多4G云打印机</div>
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title">番茄打印解决方案上线</div>
                        <div class="left_text">
                            标签/小票模板编辑与跨平台<br>打印解决方案，实现SaaS用户在线模板编辑打印。
                        </div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2021</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer3.png" alt="printer">
                            <div class="right_text">菜鸟云打印机</div>
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title">番茄标签上线</div>
                        <div class="left_text">
                            标签、小票模板编辑软件，覆盖电脑端、<br>移动端；支持多品牌机器打印。
                        </div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2020</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer4.png" alt="printer">
                            <div class="right_text">标签打印机</div>
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title">快麦云打印服务上线</div>
                        <div class="left_text">
                            软硬件结合的异地远程、多人跨端打印服务，<br>提供支持多平台对接的API接口。
                        </div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2019</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer5.png" alt="printer">
                            <div class="right_text">云小票打印机</div>
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title"></div>
                        <div class="left_text"></div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2018</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer6.png" alt="printer">
                            <div class="right_text">3寸面单打印机面世</div>
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title"></div>
                        <div class="left_text"></div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2017</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer7.png" alt="printer">
                            <div class="right_text">快麦首款云打印机</div>
                        </div>
                        <div class="right_item">
                            <img class="right_img" src="./images/printer8.png" alt="printer">
                            <div class="right_text">桌面式蓝牙打印机面世</div>
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title"></div>
                        <div class="left_text"></div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2016</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer9.png" alt="printer">
                            <div class="right_text">便携式热敏打印机</div>
                        </div>
                        <div class="right_item">
                            <img class="right_img" src="./images/printer10.png" alt="printer">
                            <div class="right_text">工业型热敏打印机面世</div>
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title"></div>
                        <div class="left_text"></div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2015</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer11.png" alt="printer">
                            <div class="right_text">快麦首款热敏打印机</div>
                        </div>
                    </div>
                </div>
                <div class="time_line_row">
                    <div class="time_line_left">
                        <div class="left_title"></div>
                        <div class="left_text"></div>
                    </div>
                    <div class="time_line_center">
                        <div class="center_year">2014</div>
                        <hr class="center_line_row"/>
                        <hr class="center_line_col"/>
                        <div class="center_line_circle"></div>
                    </div>
                    <div class="time_line_right">
                        <div class="right_item">
                            <img class="right_img" src="./images/printer12.png" alt="printer">
                            <div class="right_text">国内第一台电子面单打印机</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "About",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.about {
  padding-top: 0;

  .banner {
    background: #2B2A2A;
    height: 200rem;

    .main_content {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      height: 100%;

      .about_name_bg {
        width: 273rem;
        height: 58rem;
        background: #FF4C02;
        text-align: right;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .about_name {
          font-size: 40rem;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 500;
          color: #000000;
        }
      }

      .about_en {
        height: 103rem;
      }
    }
  }

  .about_title {
    margin-top: 70rem;
    font-size: 36rem;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 500;
    color: #FF4C02;
    line-height: 52rem;
    text-align: center;
  }

  .time_line {
    margin-top: 32rem;
    margin-bottom: 125rem;
    font-size: 12rem;

    .time_line_row {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;

      .time_line_left {
        flex: 0 0 500rem;

        .left_title {
          margin-top: 30rem;
          margin-right: 8rem;
          font-size: 18rem;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 400;
          color: #000000;
          line-height: 26rem;
          text-align: right;
        }

        .left_text {
          margin-top: 5rem;
          margin-right: 8rem;
          font-size: 14rem;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 400;
          color: #333333;
          line-height: 20rem;
          text-align: right;
        }
      }

      .time_line_center {
        flex: 0 0 200rem;
        position: relative;

        .center_img {
          position: absolute;
          left: 120rem;
          bottom: 0;
        }

        .center_year {
          position: absolute;
          left: 26rem;
          top: 28rem;
          font-size: 36rem;
          font-family: Arial, Arial;
          font-weight: 700;
          color: #FF4C02;
          line-height: 41rem;
        }

        .center_line_row {
          position: absolute;
          top: 80rem;
          left: 0;
          width: 100%;
          height: 2rem;
          background: #FF4C02;
          border: 0;
          margin: 0;
        }

        .center_line_col {
          position: absolute;
          left: 130rem;
          top: 0;
          width: 3rem;
          height: 100%;
          background: #FF4C02;
          border: 0;
          margin: 0;
        }

        .center_line_circle {
          position: absolute;
          left: 124rem;
          top: 73rem;
          width: 15rem;
          height: 15rem;
          background: #FF4C02;
          border-radius: 50%;
        }
      }

      .time_line_right {
        flex: 0 0 500rem;
        padding-bottom: 34rem;
        display: flex;
        align-items: center;

        .right_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin-left: 14rem;

          .right_img {
            width: 160rem;
          }

          .right_text {
            margin-top: 2rem;
            font-size: 14rem;
            font-family: Source Han Sans, Source Han Sans;
            font-weight: 400;
            color: #000000;
            line-height: 20rem;
            text-align: center;
          }
        }

      }
    }
  }
}
</style>
