import VueRouter from "vue-router";

import routerView from "./components/RouterView/index.vue";
import home from "./views/home/index.vue";
import history from "./views/about/history/index.vue";
import aboutUs from "./views/about/aboutUs/index.vue";

export const routes = [
    {
        path: "/home",
        name: "首页",
        component: home,
    },
    {
        path: "/about",
        name: "公司介绍",
        component: routerView,
        children: [
            {
                path: "/about/history",
                name: "发展历程",
                component: history,
            },
            {
                path: "/about/aboutUs",
                name: "了解我们",
                component: aboutUs,
            },
        ]
    },
];

const router = new VueRouter({
    routes: [
        {
            path: "/",
            redirect: "/home",
        },
        ...routes,
    ],
});

export default router;
